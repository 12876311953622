import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutCommon from '@/views/layout/anonymous/Layout'
import LayoutMember from '@/views/layout/member/Layout'
import LayoutAnonymous from '@/views/layout/anonymous/LayoutAnonymous'
import LayoutMobile from '@/views/mobile/layout/LayoutMobile'


Vue.use(VueRouter)

/** 같은 페이지에서 같은 페이지로 $router.push 한 오률를 처리함 (ex : 홈페이지에서 홈 로고를 클릭한 경우) */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    return window.location.reload()
  })
};

// const isPc = () => window.innerWidth >= 768

const routes = [{
    path: '/',
    name: 'Intro',
    redirect: {
      name: 'MainPage'
    }
  },
  {
    path: '/',
    component: LayoutCommon,
    // component: isPc() ? LayoutCommon : LayoutMobile,
    children: [
      // {
      //   path: '/about',
      //   component: () => import('../views/About'),
      //   name: 'About',
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      {
        path: '/main',
        // component: () => isPc() ? import('../views/main/Main') : import('../views/mobile/main/Main'),
        component: () => import('../views/main/Main'),
        name: 'MainPage',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/api',
        component: () => import('../views/main/MainApi'),
        name: 'MainApi',
        meta: {
          title: 'API',
          icon: '',
          noCache: true,
          affix: true
        }
      },

      {
        path: '/main/:recommendCode',
        component: () => import('../views/main/Main'),
        // redirect: { name: 'MainPage'},

      },
      {
        path: '/login',
        component: () => import('../views/login/Login'),
        name: 'LoginPage',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/sign_up',
        component: () => import('../views/login/SignUp'),
        name: 'SignUp',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/signup_agree',
        component: () => import('../views/login/SignUpAgree'),
        name: 'SignUpAgree',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/signup_done',
        component: () => import('../views/login/SignUpDone'),
        name: 'SignUpDone',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/find_id',
        component: () => import('../views/login/FindId'),
        name: 'FindId',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/password_reset',
        component: () => import('../views/login/PasswordReset'),
        name: 'PasswordReset',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },

    ]
  },
  {
    path: '/anonymous',
    component: LayoutAnonymous,
    children: [{
        path: '/cs',
        component: () => import('../views/anonymous/AnonymousAnnounce'),
        name: 'AnonymousAnnounce',
      },
      {
        path: '/withrawal',
        component: () => import('../views/anonymous/WithdrawDone'),
        name: 'WithdrawDone',
      },
      {
        path: '/policy_anonymous',
        component: () => import('../views/policy/PolicyPage'),
        name: 'AnonymousPolicy',
      },
      {
        path: '/privacy_anonymous',
        component: () => import('../views/policy/PrivacyPage'),
        name: 'AnonymousPrivacy',
      },
    ]
  },
  {
    path: '/user',
    component: LayoutMember,
    children: [{
        path: '/dashboard',
        component: () => import('../views/member/DashBoard'),
        name: 'MemberDashboard',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },

      {
        path: '/sms-send',
        component: () => import('../views/member/SmsSend'),
        name: 'SmsSend',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/sms-send-mapping',
        component: () => import('../views/member/SmsSend'),
        name: 'SmsSendMapping',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/address-entry',
        component: () => import('../views/member/MemberAddressEntry'),
        name: 'MemberAddressEntry',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/payment',
        component: () => import('../views/member/MemberPayment'),
        name: 'MemberPayment',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/send-report',
        component: () => import('../views/member/MemberSendReport'),
        name: 'MemberSendReport',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/send-report-waiting',
        component: () => import('../views/member/MemberSendReportWaiting'),
        name: 'MemberSendReportWaiting',
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/member-edit-info',
        component: () => import('../views/member/MemberEditInfo'),
        name: 'MemberEditInfo',
        props: true,
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      // {
      //   path: '/member-withraw-done',
      //   component: () => import('../views/member/MemberWithdrawDone'),
      //   name: 'MemberWithdrawDone',
      //   props : true,
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      {
        path: '/member-announce-info',
        component: () => import('../views/member/MemberAnnounce'),
        name: 'MemberAnnounce',
        props: true,
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      }, {
        path: '/member-api',
        component: () => import('../views/member/MemberApi'),
        name: 'MemberApi',
        props: true,
        meta: {
          title: 'API',
          icon: 'mdi-view-dashboard',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/policy',
        component: () => import('../views/policy/PolicyPage'),
        name: 'PolicyPage',
      },
      {
        path: '/policy/:version',
        component: () => import('../views/policy/PolicyPage'),

      },
      {
        path: '/privacy',
        component: () => import('../views/policy/PrivacyPage'),
        name: 'PrivacyPage',
      },

      {
        path: "/404",
        component: () => import('../views/member/NotFound'),
        name: "NotFound",
      },
      {
        path: '/*',
        redirect: {
          name: 'NotFound'
        },
      },
    ]
  },
  {
    path: '/number',
    component: LayoutMobile,
    children: [{
      path: 'process',
      component: () => import('../views/mobile/numberProcess/SubmitProcess1'),
      name: 'numberProcess',
    }, ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
})

export default router
